import React from 'react';
import { Button } from '@mui/material';

const reload = (
  <Button onClick={() => window.location.reload()} sx={{ color: 'white' }}>
    RELOAD
  </Button>
);

export default {
  reload,
};
