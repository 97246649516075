import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import store, { history } from './store';
import keycloakProvider from './auth/keycloak';
import { client } from './api';
const webRender = () =>
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <App store={store} history={history} />
        </Provider>
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );

keycloakProvider.initKeycloak(webRender);

serviceWorkerRegistration.register();
