import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from './Header';
import Notification, { NotificationProps } from '../notifications/Notification';
import { useAppSelector } from '../hooks';
import store from '../store';
import { popNotification, pushNotification } from '../notifications/slice';
import { styled } from '@mui/system';
import { AuthStatus } from './../auth/slice';
import keycloakProvider from 'auth/keycloak';

const MasterLayout = (props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal;
}) => {
  const [open, setOpen] = useState(false);
  const notificationState = useAppSelector(state => state.notifications);
  const authenticated = useAppSelector(state => state.auth);
  const [currentNotification, setCurrentNotification] = useState<
    NotificationProps | undefined
  >(undefined);

  // show welcome message to new users
  React.useEffect(() => {
    if (keycloakProvider.isNewUser()) {
      store.dispatch(
        pushNotification({
          children:
            "Welcome to USMART! We're setting up your account and processing your roles.",
        })
      );
    }
  }, [authenticated.tokens.idToken]);

  useEffect(() => {
    if (
      notificationState.notifications.length !== 0 &&
      !notificationState.currentNotification
    )
      store.dispatch(popNotification());
  }, [
    notificationState.currentNotification,
    notificationState.notifications.length,
  ]);

  useEffect(() => {
    setCurrentNotification(
      notificationState.currentNotification && (
        <Notification
          duration={
            (
              notificationState.currentNotification as unknown as NotificationProps
            ).duration
          }
          action={
            (
              notificationState.currentNotification as unknown as NotificationProps
            ).action
          }
        >
          {
            (
              notificationState.currentNotification as unknown as NotificationProps
            ).children
          }
        </Notification>
      )
    );
  }, [notificationState.currentNotification]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const authed = authenticated.isAuthed === AuthStatus.authenticated;

  return (
    <React.Fragment>
      <Header isAuthed={authed} drawerOpen={open} toggleDrawer={toggleDrawer} />
      <Main>
        {props.children}
      </Main>
      {currentNotification}
    </React.Fragment>
  );
};

const Main = styled('main')`
  padding-top: 64px;

  @media print {
    padding-top: 0px;
  },
`;

export default connect()(MasterLayout);
