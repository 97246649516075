import React, { FunctionComponent, useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';
import { clearNotification } from './slice';
import store from 'store';
import actions from './actions';

export type NotificationProps = {
  children: React.ReactElement | string;
  duration?: number;
  xPosition?: 'left' | 'center' | 'right';
  yPosition?: 'bottom' | 'top';
  action?: string;
}

const Notification: FunctionComponent<NotificationProps> = ({
  children,
  duration = 3500,
  xPosition = 'left',
  yPosition = 'bottom',
  action,
}) => {
  const [isOpen, setOpen] = useState(true);

  useEffect(() => {
    if (!isOpen) setTimeout(() => store.dispatch(clearNotification()), 500);
  }, [isOpen]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  console.log({ actions, action, selected: action && actions[action as keyof typeof actions] });

  return (
    <Snackbar
      anchorOrigin={{ horizontal: xPosition, vertical: yPosition }}
      open={isOpen}
      action={action && actions[action as keyof typeof actions]}
      message={typeof children === 'string' ? children : undefined}
      autoHideDuration={duration}
      onClose={handleClose}
      children={typeof children !== 'string' ? children : undefined}
    />
  );
};

export default Notification;
