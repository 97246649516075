import React, { FunctionComponent, useEffect } from 'react';
import { Button, Typography, Menu, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useAppSelector } from 'hooks';
import keycloakProvider from './keycloak';
import { PARAMS, ROUTE } from '../constants';
import { Link } from 'react-router-dom';
import { AuthStatus } from './slice';
import { gql, useQuery } from '@apollo/client';
import { Role } from 'usmart-common/dist/roles';
import store from '../store';
import { pushNotification } from '../notifications/slice';
import { isNil } from 'ramda';

const PROFILE_QUERY = gql`
  query Profile($id: String!) {
    user(id: $id) {
      id
      roles {
        name
        description
      }
      email
      firstName
      lastName
    }
  }
`;

/**
 * Determines if there is a disparity between local and server roles.
 * @param fromApi Roles retrieved from the USMART API
 * @param fromKeycloak Roles retrieved from the keycloakProvider
 * @returns True if roles match, false if not.
 */
const compareRoles = (fromApi: Role[], fromKeycloak?: string[]) => {
  if (!fromKeycloak) return false;
  for (let i = fromApi.length - 1; i >= 0; --i) {
    if (fromKeycloak.findIndex(r => r === fromApi[i].name) === -1) return false;
    fromApi.splice(i, 1);
  }
  return fromApi.length === 0; // catches if any roles are in KC but NOT api
};

const AuthButton: FunctionComponent = () => {
  const authData = useAppSelector(state => state.auth);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null); // prettier-ignore
  const [hasPromptedReload, setHasPromptedReload] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // fire request to force load roles. does not block.
  const { data } = useQuery(PROFILE_QUERY, {
    variables: { id: keycloakProvider.getIdTokenParsed()?.sub },
    skip: isNil(keycloakProvider.getIdTokenParsed()?.sub),
  });

  useEffect(() => {
    if (
      !hasPromptedReload &&
      data?.user &&
      !compareRoles([...data.user.roles], keycloakProvider.getRoles())
    ) {
      setHasPromptedReload(true);
      store.dispatch(
        pushNotification({
          children: 'New roles found. Reload to update.',
          duration: 15000,
          action: 'reload',
        })
      );
    }
  }, [data, hasPromptedReload]);

  switch (authData.isAuthed) {
    case AuthStatus.authenticated:
      return (
        <React.Fragment>
          <Button
            color="inherit"
            aria-controls="account-menu"
            aria-haspopup="true"
            onClick={handleClick}
            id="account-menu-button"
          >
            <AccountCircle />
            &nbsp;
            <Typography>{keycloakProvider.getName()}</Typography>
          </Button>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              component={Link}
              to={ROUTE.PROFILE.replace(PARAMS.USER_PARAM, 'me')}
              onClick={handleClose}
              id="profile-button"
            >
              My account
            </MenuItem>
            {keycloakProvider.hasAScope(['admin', 'users', 'roles']) && (
              <MenuItem
                component={Link}
                to={ROUTE.ADMIN}
                onClick={handleClose}
                id="admin-button"
              >
                Admin
              </MenuItem>
            )}
            <MenuItem
              onClick={() => keycloakProvider.doLogout()}
              id="logout-button"
            >
              Logout
            </MenuItem>
          </Menu>
        </React.Fragment>
      );
    case AuthStatus.anonymous:
      return (
        <Button
          color="inherit"
          onClick={keycloakProvider.doLogin}
          id="account-menu-button"
        >
          <AccountCircle />
          &nbsp;
          <Typography>Sign In</Typography>
        </Button>
      );
    case AuthStatus.pending:
    default:
      return (
        <Button color="inherit" id="account-menu-button">
          <AccountCircle />
          &nbsp;
          <Typography>Loading...</Typography>
        </Button>
      );
  }
};

export default AuthButton;
