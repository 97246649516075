import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => ({
  card: {
    padding: 10,
  },
  title: {
    color: 'green',
  },
  field: {
    fontSize: 16,
    color: 'gray',
    marginRight: 10,
  },
  fieldvalue: {
    fontSize: 16,
    color: 'black',
    '@media print': {
      fontSize: 16,
    },
  },
  spacer: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing(4),
  },
  groupContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      width: '50%',
      paddingRight: theme.spacing(4),
    },
  },
  profileFieldLabel: {
    fontSize: 16,
    color: 'gray',
    marginBottom: -theme.spacing(0.5),
  },
  profileFieldValue: {
    fontSize: 18,
    fontWeight: 500,
  },
  profileInformationCard: {
    marginTop: theme.spacing(2),
  },
  Roles: {
    margin: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
  centeredTextWithPadding: {
    textAlign: 'center',
    paddingTop: theme.spacing(5),
  },
  spaceRight: {
    marginRight: theme.spacing(1),
  },
} as const);

export const componentMakeStyles = makeStyles(styles);
