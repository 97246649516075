import './App.css';

import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { History } from 'history';
import keycloakProvider from './auth/keycloak';
import { lazy, Suspense } from 'react';
import { ROUTE } from './constants';
import { Store } from '@reduxjs/toolkit';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Scope, AccessLevel } from 'usmart-common/dist/roles';
import Layout from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import store from 'store';
import startNetworkListener from 'sync/online';

const AdminPanel = lazy(() => import('./admin'));
const ControlDetail = lazy(() => import('./control/Details'));
const ControlEdit = lazy(() => import('./control/Edit'));
const Controls = lazy(() => import('./control/List'));
const MapPage = lazy(() => import('./map/MapPage'));
const Home = lazy(() => import('./components/Home'));
const UserProfile = lazy(() => import('./auth/UserProfile'));
const ApprovalQueue = lazy(() => import('./control/ApprovalQueue'));
const Cache = lazy(() => import('./control/Cache'));
const SyncList = lazy(() => import('./sync/List'));
const ProjectsPage = lazy(() => import('./projects'));
const ReportsPage = lazy(() => import('./report/List'));
const ViewProject = lazy(() => import('./projects/ProjectPage'));
const Bulk = lazy(() => import('./bulk/Bulk'));
const Unauthorized = lazy(() => import('./auth/Unauthorized'));
const Forbidden = lazy(() => import('./auth/Forbidden'));
const CuiModal = lazy(() => import('./components/CuiModal'));

export const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(0,0,0,.9)',
    },
    secondary: {
      main: 'rgb(222, 30 46)',
    },
  },

  typography: {
    fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
  },
});

type Props = {
  history: History;
  store: Store;
};

startNetworkListener(store);

const App = (_props: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Layout>
          <StyledEngineProvider injectFirst>
            <Suspense fallback={null}>
              <Routes>
                <Route
                  path={ROUTE.POINTS_ADD}
                  element={
                    <ProtectedRoute
                      Component={ControlEdit}
                      canAccess={() =>
                        keycloakProvider.hasAccess(
                          Scope.controlPoints,
                          AccessLevel.write
                        )
                      }
                    />
                  }
                />
                <Route
                  path={ROUTE.POINTS_EDIT}
                  element={
                    <ProtectedRoute
                      Component={ControlEdit}
                      canAccess={() =>
                        keycloakProvider.hasAccess(
                          Scope.controlPoints,
                          AccessLevel.write
                        )
                      }
                    />
                  }
                />
                <Route
                  path={ROUTE.POINTS_FROM_NGS}
                  element={
                    <ProtectedRoute
                      Component={ControlEdit}
                      canAccess={() =>
                        keycloakProvider.hasAccess(
                          Scope.controlPoints,
                          AccessLevel.write
                        )
                      }
                    />
                  }
                />
                <Route
                  path={ROUTE.SYNC}
                  element={
                    <ProtectedRoute
                      Component={SyncList}
                      canAccess={() =>
                        keycloakProvider.hasAccess(
                          Scope.controlPoints,
                          AccessLevel.write,
                        )
                      }
                    />
                  }
                />
                <Route
                  path={ROUTE.POINTS_FROM_OPUS}
                  element={
                    <ProtectedRoute
                      Component={ControlEdit}
                      canAccess={() =>
                        keycloakProvider.hasAccess(
                          Scope.controlPoints,
                          AccessLevel.write
                        )
                      }
                    />
                  }
                />
                <Route
                  path={ROUTE.POINTS_PREPARE}
                  element={
                    <ProtectedRoute
                      Component={ControlEdit}
                      canAccess={() =>
                        keycloakProvider.hasAccess(
                          Scope.controlPoints,
                          AccessLevel.write
                        )
                      }
                    />
                  }
                />
                <Route path={ROUTE.POINTS_DETAIL} element={<ControlDetail />} />
                <Route path={ROUTE.POINTS} element={<Controls />} />
                <Route path={ROUTE.CACHE} element={<Cache />} />
                <Route path={ROUTE.MAP} element={<MapPage />} />
                <Route
                  path={ROUTE.REPORTS_VIEW}
                  element={<ReportsPage />}
                ></Route>
                <Route path={ROUTE.PROJECTS} element={<ProjectsPage />} />
                <Route path={ROUTE.BULK_UPLOAD_VIEW} element={<Bulk />}></Route>
                <Route
                  path={ROUTE.PROJECT_VIEW}
                  element={
                    <ProtectedRoute
                      Component={ViewProject}
                      canAccess={() => keycloakProvider.isLoggedIn()!}
                    />
                  }
                />
                <Route
                  path={ROUTE.PROFILE}
                  element={
                    <ProtectedRoute
                      Component={UserProfile}
                      canAccess={() => keycloakProvider.isLoggedIn()!}
                    />
                  }
                />
                <Route
                  path={ROUTE.ADMIN}
                  element={
                    <ProtectedRoute
                      Component={AdminPanel}
                      canAccess={() =>
                        keycloakProvider.hasAScope(['admin', 'users', 'roles'])
                      }
                    />
                  }
                />
                <Route
                  path={ROUTE.APPROVAL_QUEUE}
                  element={
                    <ProtectedRoute
                      Component={ApprovalQueue}
                      canAccess={() =>
                        keycloakProvider.hasAccess(
                          Scope.controlPoints,
                          AccessLevel.manage
                        )
                      }
                    />
                  }
                />
                <Route path={ROUTE.HOME} element={<Home />} />
                <Route path={ROUTE.UNAUTHORIZED} element={<Unauthorized />} />
                <Route path={ROUTE.FORBIDDEN} element={<Forbidden />} />
              </Routes>
              <CuiModal/>
            </Suspense>
          </StyledEngineProvider>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
