import { AuthStatus } from '../auth/slice';
import { ROUTE } from '../constants';
import { useAppSelector } from 'hooks';
import { Typography } from '@mui/material';
import { componentMakeStyles } from 'styles';
import { Navigate } from 'react-router-dom';
import React, { FunctionComponent } from 'react';

type ProtectedRouteProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.LazyExoticComponent<React.ComponentType<any>>;
  canAccess: () => boolean;
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  Component,
  canAccess,
}) => {
  const authData = useAppSelector(state => state.auth);
  const classes = componentMakeStyles();

  switch (authData.isAuthed) {
    case AuthStatus.pending:
      return (
        <Typography
          className={classes.centeredTextWithPadding}
          variant="h3"
          component="h2"
        >
          Please wait, checking your credentials...
        </Typography>
      );
    case AuthStatus.authenticated:
      // return Forbidden if users are authed but not allowed to see this page
      if (!canAccess()) {
        return <Navigate to={ROUTE.FORBIDDEN} />;
      }
      return <Component />;

    case AuthStatus.anonymous:
    default:
      return <Navigate to={ROUTE.UNAUTHORIZED} />;
  }
};

export default ProtectedRoute;