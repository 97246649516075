import Dexie from 'dexie';
import 'dexie-observable';
import queue from './queue';
import control from './control';
import blob from './blob';
import * as t from './types';
import { UUID } from 'io-ts-types';

export class DB extends Dexie {
  eav: Dexie.Table<t.EAV, string>;
  aev: Dexie.Table<t.AEV, string>;
  ave: Dexie.Table<t.AVE, string>;
  doc: Dexie.Table<object, UUID>;
  spatial: Dexie.Table<t.Spatial, string>;
  queue: Dexie.Table<t.QueueOp, string>;
  blobstore: Dexie.Table<t.Blob, string>;

  constructor() {
    super('USmartDatabase');
    this.version(8).stores({
      doc: '++id, document',
      aev: 'key,docId',
      eav: 'key,docId',
      ave: 'key,docId',
      spatial: 'geohash,type,docId',
      queue: 'id,key,synced',
      blobstore: 'key,kind',
    });

    this.aev = this.table('aev');
    this.doc = this.table('doc');
    this.ave = this.table('ave');
    this.eav = this.table('eav');
    this.spatial = this.table('spatial');
    this.queue = this.table('queue');
    this.blobstore = this.table('blobstore');
  }
}

let db: DB | undefined = undefined;

export const getDB = (): DB => {
  if (!db) {
    db = new DB();
  }
  return db as DB;
};

export const deleteDB = () => {
  const db = getDB();
  indexedDB.deleteDatabase(db.name);
};

export default { queue, control, blob };
