import { Observable, of } from 'rxjs';
import { mergeMap, filter } from 'rxjs/operators';
import { Action } from '@reduxjs/toolkit';
import { actions } from './slice';
import { actions as syncActions } from './../sync/slice';
import { Dependencies, RootAppState } from '../store';
import { combineEpics, StateObservable } from 'redux-observable';
import { deserialize, serialize } from 'usmart-common';

export const addEpic = (
  action$: Observable<Action>,
  _: StateObservable<RootAppState>,
  { docs }: Dependencies
) =>
  action$.pipe(
    filter(actions.addRequest.match),
    mergeMap(({ payload }) => docs.create(deserialize(payload))),
    mergeMap(cp =>
      of(
        actions.addSuccess(serialize(cp)),
        // syncActions.resume(),
        syncActions.updateUnsyncCountRequest()
      )
    )
  );

export const updateEpic = (
  action$: Observable<Action>,
  _: StateObservable<RootAppState>,
  { docs }: Dependencies
) =>
  action$.pipe(
    filter(actions.updateRequest.match),
    mergeMap(({ payload }) => docs.update(deserialize(payload))),
    mergeMap(cp =>
      of(
        actions.updateSuccess(serialize(cp)),
        // syncActions.resume(),
        syncActions.updateUnsyncCountRequest()
      )
    )
  );

export const approveEpic = (
  action$: Observable<Action>,
  _: StateObservable<RootAppState>,
  { docs }: Dependencies
) =>
  action$.pipe(
    filter(actions.approveRequest.match),
    mergeMap(({ payload }) => docs.approve(payload)),
    mergeMap(cp =>
      of(
        actions.approveSuccess(serialize(cp)),
        // syncActions.resume(),
        syncActions.updateUnsyncCountRequest()
      )
    )
  );

export const rejectEpic = (
  action$: Observable<Action>,
  _: StateObservable<RootAppState>,
  { docs }: Dependencies
) =>
  action$.pipe(
    filter(actions.rejectRequest.match),
    mergeMap(({ payload }) => docs.reject(payload)),
    mergeMap(cp =>
      of(
        actions.rejectSuccess(serialize(cp)),
        // syncActions.resume(),
        syncActions.updateUnsyncCountRequest()
      )
    )
  );

export const removeEpic = (
  action$: Observable<Action>,
  _: StateObservable<RootAppState>,
  { docs }: Dependencies
) =>
  action$.pipe(
    filter(actions.removeRequest.match),
    mergeMap(({ payload }) => docs.remove(payload)),
    mergeMap(() =>
      of(actions.removeSuccess(), syncActions.updateUnsyncCountRequest())
    )
  );

export const addFileEpic = (
  action$: Observable<Action>,
  _: StateObservable<RootAppState>,
  { blobs }: Dependencies
) =>
  action$.pipe(
    filter(actions.addFileRequest.match),
    mergeMap(v => {
      return blobs.put(
        v.payload.document,
        v.payload.blob,
        v.payload.controlPointId,
        v.payload.index
      );
    }),
    mergeMap(() =>
      of(actions.addFileSuccess(), syncActions.updateUnsyncCountRequest())
    )
  );

export const removeFileEpic = (
  action$: Observable<Action>,
  _: StateObservable<RootAppState>,
  { blobs }: Dependencies
) =>
  action$.pipe(
    filter(actions.removeFileRequest.match),
    mergeMap(v => {
      return blobs.remove(v.payload);
    }),
    mergeMap(() =>
      of(actions.removeFileSuccess(), syncActions.updateUnsyncCountRequest())
    )
  );

export default combineEpics(addEpic, updateEpic, approveEpic, rejectEpic);

export const fileEpics = combineEpics(addFileEpic);
