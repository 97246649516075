import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { getDB } from 'db';
import queue from 'db/queue';

export enum ConnectionState {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export type SyncError = { error: string; id: string; key: string };

const name = 'sync';

export const initialState = {
  running: false,
  connectionState: ConnectionState.OFFLINE,
  serverReachable: false,
  progress: 0,
  errors: [] as SyncError[],
};

export const send = createAction(`${name}/send`);
export const recv = createAction(`${name}/recv`);
export const online = createAction(`${name}/online`);
export const offline = createAction(`${name}/offline`);
export const reachable = createAction(`${name}/reachable`);
export const unreachable = createAction(`${name}/unreachable`);
export const updateUnsyncCountRequest = createAction(`${name}/unsync_request`);
export const updateUnsyncCountFulfilled = createAction(
  `${name}/unsync_fulfilled`
);

const db = getDB();

export const unsyncedPointCount = () => queue(db).count();
export const unsyncedPoints = queue(db).getAll;

export const syncSlice = createSlice({
  name,
  initialState,
  reducers: {
    init: state => {
      state.running = initialState.running;
      state.connectionState = ConnectionState.OFFLINE;
    },
    reachable: state => {
      state.serverReachable = true;
    },
    unreachable: state => {
      state.serverReachable = false;
      state.running = false;
    },
    online: state => {
      state.connectionState = ConnectionState.ONLINE;
    },
    offline: state => {
      state.connectionState = ConnectionState.OFFLINE;
      state.serverReachable = false;
    },
    resume: state => {
      state.running = true;
    },
    clearQueue: state => {
      state.running = false;
    },
    queueCleared: state => {
      state.running = false;
    },
    pause: state => {
      state.running = false;
    },
    updateUnsyncCountFulfilled: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    pushError: (state, action: PayloadAction<SyncError>) => {
      state.errors.push(action.payload);
    },
    popError: (state, action: PayloadAction<string>) => {
      const errorIdx = state.errors.findIndex(e => e.key === action.payload);
      if (errorIdx > -1) state.errors.splice(errorIdx, 1);
    },
  },
});

export const actions = { ...syncSlice.actions, updateUnsyncCountRequest };
export default syncSlice.reducer;
